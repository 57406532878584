<template>
  <v-dialog
    :value="printInvoiceModal"
    persistent
    fullscreen
    @keydown.enter="print"
  >
    <v-card>
        <v-card-title>Print factuur</v-card-title>
      <v-row>
        <v-col cols="6">
            <input
              class="hidden"
              id="input"
              @focus="closePrintInvoiceModal"
              type="text"
            />
          <div class="settings">
             <label>Briefpapier:</label
              ><input
                @change="viewInvoice"
                type="checkbox"
                v-model="options.stationary"
              />
              <label><img src="/img/ideal.png" height="50" alt="ideal"></label
              ><input
                @change="viewInvoice"
                type="checkbox"
                v-model="options.qrcode"
              />
              <label>Herinnering</label>
           <select @change="viewInvoice" v-model="options.reminderType">
             <option v-for="status of options.reminderTypes" :value="status.status" :key="status.status">{{status.text}}</option>
           </select>
              <template v-if="isElectron">
              <label>Printer:</label
              ><select v-model="options.printer"
                ><option
                  v-for="printer of printers"
                  :key="printer.name"
                  :value="printer.name"
                >
                  {{ printer.displayName }}
                </option>
              </select>
              <label>Aantal:</label
              ><input type="number" v-model="options.copies" />
              <label>Formaat:</label
              ><select v-model="options.pageSize"
                ><option
                  v-for="pageSize of pageSizes"
                  :key="pageSize.value"
                  :value="pageSize.value"
                >
                  {{ pageSize.text }}
                </option>
              </select>
            </template>
          </div>
        </v-col>
        <v-col cols="6">
          <iframe
            :src="dataUrl"
            frameborder="0"
            id="iframe"
          ></iframe>
        </v-col>

        <v-footer fixed class="d-flex justify-center">
          <v-btn-toggle>
            <v-btn @click="closePrintInvoiceModal" color="primary"
              >Sluit</v-btn
            >
            <v-btn color="primary" @click="print">Afdrukken</v-btn>
          </v-btn-toggle>
        </v-footer>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import { viewInvoice,printInvoice } from "../js/pdfController"
export default {
  name: "printInvoiceModal",
  props: {
    printInvoiceModal: Boolean,
    invoice: {}
  },
  data() {
    return {
      dataUrl: "",
      buffer: "",
      iframeLoaded: false,
      pageSizes: [
        {value: "A4", text: "A4"},
        {value: "A5", text: "A5"},
      ],
      options: {
        stationary: true,
        pageSize: "A4",
        copies: 1,
        printer: "",
        qrcode: true,
        reminderType: 0,
        reminderTypes:[
        {status:0,text:"Geen"},
        {status:1,text:"Herinnering"},
        {status:2,text:"Aanmaning"}
      ]
      },
    }
  },
  methods: {
    getPrinterList() {
      window.ipcRenderer.send("getPrinterList", null)
      window.ipcRenderer.receive("getPrinterList", data => {
        this.printers = data
      })
    },
    iframeOnload() {
      let iframe = document.getElementById("iframe")
      iframe.removeEventListener("load", this.iframeOnload)
      if (
        iframe.contentDocument.readyState === "complete" &&
        this.iframeLoaded
      ) {
        this.printInvoice()
      } else {
        var that = this
        setTimeout(function() {
          return that.iframeOnload(iframe)
        }, 1000)
      }
    },
    async print() {
       if (this.isElectron) {
        this.printInvoice()
      } else {
      document
        .getElementById("iframe")
        .addEventListener("load", this.iframeOnload)
        await this.viewInvoice()
      this.iframeLoaded = true
      }
    },
    async printInvoice() {
      localStorage.setItem("printInvoiceOptions", JSON.stringify(this.options))
      if (this.isElectron) {
        let options = {
          printer: this.options.printer,
          paperSize: this.options.pageSize,  
          copies: this.options.copies,
          scale: "fit",
          silent: true
        }
        this.buffer = await printInvoice(this.invoice, this.options)
        window.ipcRenderer.send("printPdfBuffer", {
          buffer: this.buffer,
          options: options
        })
      } else document.getElementById("iframe").contentWindow.print()
      let invoice = this.invoice
      invoice.printed= true
      invoice.status = invoice.status <= 1 ? 1 : invoice.status
      await this.$store.dispatch("fetchPOST",{type:"invoicePrinted",data:invoice})
      setTimeout(() => {
        let input = document.getElementById("input")
        input.focus()
      }, 1000)
    },
    closePrintInvoiceModal() {
      URL.revokeObjectURL(this.dataUrl)
      this.$emit("close-print-invoice-modal")
    },
    async viewInvoice() {
      let data = await viewInvoice(this.invoice, this.options)
      this.dataUrl = URL.createObjectURL(data)
    }
  },
  computed: {
    isElectron() {
      return this.$store.state.isElectron 
    },
    printers(){
      return this.$store.state.printers
    }
  },
  async mounted() {
      if (JSON.parse(localStorage.getItem("printInvoiceOptions")) !== null) {
      this.options = JSON.parse(localStorage.getItem("printInvoiceOptions"))
      // this.options.qrcode = false
    }
    await this.viewInvoice(this.invoice, this.options)
  }
}
</script>

<style scoped>
div.settings {
  justify-content: space-evenly;
  font-size: 2em;
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 5px;
}
input[type="checkbox"] {
  height: 1.6em;
  width: 1em;
}
div.settings label {
  text-align: right;
}
#iframe {
  height: 700px;
  width: 600px;
  margin: auto;
  padding: 0;
  display: block;
}
.hidden {
  width: 0;
  height: 0;
  overflow: hidden;
}
</style>